import React, { useEffect, useState } from 'react'
import { motion, useMotionValue, useSpring } from 'framer-motion'

export default function CustomCursor() {
  //Select the cursor
  // const cursor = document.querySelectorAll(".cursor")
  //Use motion values rather than state
  const cursorX = useMotionValue(-100)
  const cursorY = useMotionValue(-100)
  //Add nice damping effect you can fiddle around with this
  const springConfig = { damping: 45, stiffness: 420 }
  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)
  //Add an extra state to animate hover
  const [isHovering, setIsHovering] = useState(false)
  //befroe mounting grab links and put them inside an array then add listeners that set state to hovered

  useEffect(() => {
    const links = [...window.document.querySelectorAll('.item')]

    const handleEnter = () => {
      setIsHovering(true)
    }
    const handleExit = () => {
      setIsHovering(false)
    }
    links.map((link) => link.addEventListener('mouseenter', handleEnter))
    links.map((link) => link.addEventListener('mouseleave', handleExit))

    return () => {
      links.map((link) => link.removeEventListener('mouseenter', handleEnter))
      links.map((link) => link.removeEventListener('mouseleave', handleExit))
    }
  }, [cursorX, cursorY])
  //This creates the wobble effect on moveCursor

  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX - 6)
      cursorY.set(e.clientY - 6)
    }

    window.addEventListener('mousemove', moveCursor)

    return () => {
      window.removeEventListener('mousemove', moveCursor)
    }
  }, [])

  return (
    <motion.div
      className="cursor"
      animate={{
        scale: isHovering ? 1.7 : 0.4,
      }}
      r={50}
      style={{
        translateX: cursorXSpring,
        translateY: cursorYSpring,
      }}
    />
  )
}
