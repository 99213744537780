import React from "react"
import { func, string } from "prop-types"
import styled from "styled-components"
import { motion } from "framer-motion"
import { Moon, Sun } from "react-feather"

const transition = { duration: 2, ease: [0.6, -0.5, 0.01, 0.9] }

const toggle = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.09,
      staggerDirection: 1,
      delay: 3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
}

const Buttonwrapper = styled(motion.div)`
  grid-column: 5;
`
const Toggle = ({ theme, toggleTheme }) => {
  return (
    <Buttonwrapper
      initial="hidden"
      exit="exit"
      animate="visible"
      variants={toggle}
      transition={{ ...transition }}
    >
      <button onClick={toggleTheme} aria-label="Toggle dark and light mode">
        {theme === "dark" ? (
          <Sun color="white" size={25} />
        ) : (
          <Moon color="#034738" size={25} />
        )}
      </button>
    </Buttonwrapper>
  )
}

Toggle.propTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired,
}

export default Toggle
