// import GlobalFonts from '../font/fonts'

export const Darktheme = {
  font: {
    primary: `'Cambon', serif`,
    secondary: `'Neue plak', serif`,
  },
  font_size: {
    small: 'font-size: 20px; line-height: 30px',
    regular: 'font-size: 24px; line-height: 32px',
    large: 'font-size: 30px; line-height: 40px',
    medium: 'font-size: 36px; line-height: 48px',
    xlarge: 'font-size: 72px; line-height: 56px',
  },
  maxWidths: {
    general: '1600px',
    project: 1600,
  },
  gridColumns: 2, // Number of columns of the grid on the index page
  contentPadding: '1.0875rem',
  color: {
    white: '#fff',
    black: '#191414',
    jade: '#1fc58e',
    yellow: '#fae62d',
    red: '#fd483a',
    honey: '#f59b23',
    floral: '#bda5cf',
    pink: '#ffcdd2',
    background: "#191414",
    heading: "#fff",
    paragraph:"#fff"
  },
  screen: {
    xs: '400px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
}

export const Lighttheme = {
  font: {
    primary: `'Cambon', serif`,
    secondary: `'Neue plak', serif`,
  },
  font_size: {
    small: 'font-size: 20px; line-height: 30px',
    regular: 'font-size: 24px; line-height: 32px',
    large: 'font-size: 30px; line-height: 40px',
    medium: 'font-size: 36px; line-height: 48px',
    xlarge: 'font-size: 72px; line-height: 56px',
  },
  maxWidths: {
    general: '1600px',
    project: 1600,
  },
  gridColumns: 2, // Number of columns of the grid on the index page
  contentPadding: '1.0875rem',
  color: {
    white: '#fff',
    black: '#191414',
    jade: '#1fc58e',
    yellow: '#fae62d',
    red: '#fd483a',
    honey: '#f59b23',
    floral: '#bda5cf',
    pink: '#ffcdd2',
    background: "#FCF7EC", //change to cream
    heading: "#1C5746",
    paragraph: "#034738",
  },
  screen: {
    xs: '400px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
}


