import { createGlobalStyle } from 'styled-components'

import CambonBoldWoff from '../font/Cambon-Bold.woff'
import CambonBold from '../font/Cambon-Bold.woff2'

import NeuePlakWoff from './NeuePlak-ExtendedThin.woff'
import NeuePlak from './NeuePlak-ExtendedThin.woff2'

export default createGlobalStyle`
    @font-face {
        font-family: 'Cambon';
        src: url(${CambonBold}) format('woff2'),
             url(${CambonBoldWoff}) format('woff');
        font-weight: 100;
        font-style:normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Neue Plak';
        src: url(${NeuePlak}) format('woff2'),
             url(${NeuePlakWoff}) format('woff');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }
`
