import React, { useState, useEffect } from "react"
import { ThemeProvider } from "styled-components"
import styled from "styled-components"
import { motion } from "framer-motion"

import GlobalFonts from "../../font/fonts"

import Cursor from "../customCursor"
import SEO from "./SEO"
import Toggle from "../Toggler"
import Logo from "../sections/Logo"

import { useDarkMode } from "../../hooks/useDarkmode"
import GlobalStyles from "../../styles/GlobalStyles"
import { Lighttheme, Darktheme } from "../../styles/theme"

const transition = { duration: 2, ease: [0.6, -0.5, 0.01, 0.9] }

const headerVariant = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",

      delay: 5,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
}

const HeaderWrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 50px 50px;
  place-items: center;

  .toggle {
    grid-row: 1;
    grid-column: 5;
    margin-top: -3px;
  }
`

function Layout({ children }) {
  const [theme, themeToggler] = useDarkMode()
  const themeMode = theme === "light" ? Lighttheme : Darktheme

  return (
    <ThemeProvider theme={themeMode}>
      <>
        <SEO />
        <GlobalFonts />
        <GlobalStyles />
        <Cursor />
        <HeaderWrapper
          as="header"
          className="wrapper"
          initial="hidden"
          animate="visible"
          variants={headerVariant}
          transition={{ ...transition }}
        >
          <Logo className="logo"></Logo>
          <Toggle className="toggle" theme={theme} toggleTheme={themeToggler} />
        </HeaderWrapper>
        {children}
      </>
    </ThemeProvider>
  )
}

export default Layout
