import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import LogoSVG from "../../data/svg/dojo_logo.svg"
import { motion } from "framer-motion"

const transition = { duration: 2, ease: [0.6, -0.5, 0.01, 0.9] }

const LogoWrap = styled(motion.div)`
  grid-column: 4;
  grid-row: 1;

  svg {
    width: 50px;
    height: 50px;
  }
`

const SVG = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.09,
      staggerDirection: 1,
      delay: 3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
}
//Motion Varian

function Logo() {
  return (
    <>
      <LogoWrap
        initial="hidden"
        exit="exit"
        animate="visible"
        variants={SVG}
        transition={{ ...transition }}
      >
        <Link to="/">
          <LogoSVG />
        </Link>
      </LogoWrap>
    </>
  )
}

export default Logo
